import { PauseChannelListData, PauseChannelListLabel } from './PauseChannelList';
import { PauseChannelListItemMock } from '../pauseChannelListItem';
import moment from 'moment';

const label: PauseChannelListLabel = {
  title: 'Active channels',
  pauseChannelListItem: PauseChannelListItemMock.label,
};

const activeLabel: PauseChannelListLabel = {
  ...label,
  title: 'Active channels',
};

const pausedLabel: PauseChannelListLabel = {
  ...label,
  title: 'Paused channels',
};

const activeListData: PauseChannelListData = {
  channelStatus: [
    {
      channel: 'UBER_EATS',
      channelName: 'UberEats',
    },
    {
      channel: 'DOOR_DASH',
      channelName: 'DoorDash',
    },
  ],
};

const activeListDataDisabled: PauseChannelListData = {
  ...activeListData,
  disabled: true,
};

const pausedListData: PauseChannelListData = {
  channelStatus: [
    {
      channel: 'SKIP_THE_DISHES',
      channelName: 'SkipTheDishes',
      pausedUntil: moment().add(35, 'minute').toISOString(),
    },
    {
      channel: 'GOOGLE_FOOD_ORDERING',
      channelName: 'Order with Google',
      pausedUntil: moment().add(30, 'minute').toISOString(),
    },
  ],
};

const pausedListDataDisabled: PauseChannelListData = {
  ...pausedListData,
  disabled: true,
};

const pendingListData: PauseChannelListData = {
  channelStatus: [
    {
      channel: 'SKIP_THE_DISHES',
      channelName: 'SkipTheDishes',
      pending: true,
    },
    {
      channel: 'GOOGLE_FOOD_ORDERING',
      channelName: 'Order with Google',
      pending: true,
    },
  ],
};

const emptyListData: PauseChannelListData = {
  channelStatus: [],
};

export const PauseChannelListMock = {
  activeLabel,
  pausedLabel,
  activeListData,
  activeListDataDisabled,
  pausedListData,
  pausedListDataDisabled,
  pendingListData,
  emptyListData,
};
