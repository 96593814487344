import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { rem } from '../app/AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      display: 'block',
      flexBasis: '60%',
      fontWeight: 'bold',
      marginBottom: rem(theme.spacing(1)),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    price: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexGrow: 1,
      flexBasis: '40%',
      fontWeight: 'bold',
      marginBottom: rem(theme.spacing(1)),
    },
    default: {
      display: 'block',
      flexBasis: '100%',
      marginBottom: rem(theme.spacing(1)),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  })
);

export enum ElementType {
  Price = 'price',
  Name = 'name',
}

interface Props {
  children: React.ReactNode;
  type: ElementType;
}

export default function ItemElement({ type, children }: Props) {
  const classes = useStyles();
  const paragraphStyles = getElementClass(type, classes);

  return <p className={paragraphStyles}>{children}</p>;
}

function getElementClass(type: ElementType, classes: Record<'name' | 'price' | 'default', string>) {
  switch (type) {
    case ElementType.Price:
      return classes.price;
    case ElementType.Name:
      return classes.name;
    default:
      return classes.default;
  }
}
