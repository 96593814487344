import { faCirclePlay } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionButtonsLabel } from '@ueat/poke';

import { DelaySelection, DelaySelectionLabel } from '../delaySelection';
import { usePauseChannelDrawer } from '../hooks/usePauseChannelDrawer';
import { PauseChannelListContainer, PauseChannelListContainerLabel } from '../pauseChannelListContainer';
import { ChannelStates, OrderChannelEnum } from '../types/PauseTypes';
import { Drawer, DrawerHeader, DrawerContent, DrawerFooter } from '../../components/drawer';

export interface PauseChannelLabel {
  title: string;
  actionButtonNext: ActionButtonsLabel;
  delaySelectionLabel: DelaySelectionLabel;
  listContainerLabel: PauseChannelListContainerLabel;
}

export interface PauseChannelData {
  branchId: number;
  channelList: ChannelStates[];
  open: boolean;
}

export interface PauseChannelProps {
  data: PauseChannelData;
  onCloseDrawer: () => void;
  onResumeChannel: (channelsToResume: OrderChannelEnum[]) => void;
  handlePauseSuccessNotification: (pauseStates: ChannelStates[]) => void;
  handlePauseErrorNotification: () => void;
}

export const PauseChannelDrawer = ({
  data,
  onCloseDrawer,
  onResumeChannel,
  handlePauseSuccessNotification,
  handlePauseErrorNotification,
}: PauseChannelProps) => {
  const {
    state,
    isNextButtonDisabled,
    isBackButtonDisabled,
    isPrimaryButtonStartIconDisplayed,
    isPrimaryButtonEndIconDisplayed,
    handleSelectDelay,
    handleSynchronizeDelay,
    handleSelectItem,
    handleNextAction,
    handleBackAction,
    handleTimeExpiry,
  } = usePauseChannelDrawer({
    data,
    onCloseDrawer,
    onResumeChannel,
    handlePauseSuccessNotification,
    handlePauseErrorNotification,
  });

  const { label } = state;
  const wizardComponentsMap = {
    channelsSelection: (
      <PauseChannelListContainer
        label={label.listContainerLabel}
        data={{
          activeChannelList: state.activeChannelList,
          activeListDisabled: state.isActiveListDisabled,
          pausedChannelList: state.pausedChannelList,
          pausedListDisabled: state.isPausedListDisabled,
        }}
        onSelected={handleSelectItem}
        onTimeDelayExpiry={handleTimeExpiry}
      />
    ),
    delaySelection: (
      <DelaySelection
        label={label.delaySelectionLabel}
        data={{
          delaysList: state.delaysList,
          selectedChannelsToPauseIdsList: state.selectedChannelsToPauseIdsList,
          showApplyToAllPausedChannelsCheckbox: state.showApplyToAllPausedChannelsCheckbox,
          synchronizeDelay: state.synchronizeDelay,
        }}
        onSelectedDelayChanged={handleSelectDelay}
        onSynchronizeDelayChange={handleSynchronizeDelay}
      />
    ),
  };

  return (
    <Drawer open={data.open} onClose={onCloseDrawer}>
      <DrawerHeader onClose={onCloseDrawer}>{label.title}</DrawerHeader>
      <DrawerContent>{wizardComponentsMap[state.currentStep]}</DrawerContent>
      <DrawerFooter
        label={label.actionButtonNext}
        data={{
          primaryButtonDisabled: isNextButtonDisabled,
          primaryButtonStartIcon: isPrimaryButtonStartIconDisplayed && <FontAwesomeIcon icon={faCirclePlay} />,
          primaryButtonEndIcon: isPrimaryButtonEndIconDisplayed && <FontAwesomeIcon icon={faArrowRight} />,
          primaryButtonTestId: 'pause-next-button',
          secondaryButtonDisabled: isBackButtonDisabled,
          secondaryButtonStartIcon: <FontAwesomeIcon icon={faArrowLeft} />,
          secondaryButtonTestId: 'pause-back-button',
        }}
        onPrimaryButtonClick={handleNextAction}
        onSecondaryButtonClick={handleBackAction}
      />
    </Drawer>
  );
};
