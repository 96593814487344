import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Stack } from '@mui/system';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Menu from '../appMenu/Menu';
import { Sections, SectionShortName } from '../appMenu/SectionsInfo';
import UeatLogo from '../auth/ueat-logo.svg';
import PauseContainer from '../pauseChannel/pauseContainer/PauseContainer';
import { rem } from './AppUtilities';
import HeaderTitle from './HeaderTitle';
import { useSettings } from './SettingsContext';

const drawerWidthNormal = '40vw';
const drawerWidthLarge = '30vw';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      zIndex: theme.zIndex.drawer - 1,
    },
    menuButton: {
      marginRight: rem(36),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidthNormal,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('md')]: {
        width: drawerWidthLarge,
      },
    },
    drawerOpen: {
      width: drawerWidthNormal,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('md')]: {
        width: drawerWidthLarge,
      },
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: rem(theme.spacing(7) + 1),
      [theme.breakpoints.up('sm')]: {
        width: rem(theme.spacing(9) + 1),
      },
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: `0 ${rem(theme.spacing(1))}`,
      ...theme.mixins.toolbar,
      minHeight: rem(56),
      [theme.breakpoints.up('sm')]: {
        minHeight: rem(64),
      },
    },
    logo: {
      width: rem(150),
      margin: rem(16),
    },
    regularToolBar: {
      alignItems: 'stretch',
      minHeight: rem(56),
      [theme.breakpoints.up('sm')]: {
        minHeight: rem(64),
      },
    },
    toolBarGutters: {
      paddingLeft: rem(theme.spacing(2)),
      paddingRight: rem(theme.spacing(2)),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: rem(theme.spacing(3)),
        paddingRight: rem(theme.spacing(3)),
      },
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: rem(theme.spacing(3)),
      paddingTop: rem(theme.generalStyles.appBarPadding - 5),
      [theme.breakpoints.up('sm')]: {
        paddingTop: rem(theme.generalStyles.appBarPadding),
      },
      height: '100vh',
      overflowX: 'auto',
      position: 'relative',
      color: theme.palette.secondary.main,
    },
    barContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
    },
    sectionTitle: {
      '& h6:first-letter': {
        textTransform: 'capitalize',
      },
      display: 'flex',
      alignItems: 'center',
      minWidth: '0px',
    },
    btnSettings: {
      display: 'flex',
      flexShrink: 0,
      justifyContent: 'flex-end',
    },
    headerRightText: {
      textAlign: 'right',
    },
    headerIcons: {
      color: 'white',
      height: rem(theme.generalStyles.mediumIconSize),
      width: rem(theme.generalStyles.mediumIconSize),
    },
    settingHeaderIcon: {
      color: 'white',
      height: rem(theme.generalStyles.generalIconSize),
      width: rem(theme.generalStyles.generalIconSize),
      padding: 0,
    },
    settingsIconContainer: {
      height: rem(45),
      width: rem(45),
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      borderRadius: rem(8),
      background: 'rgba(255, 255, 255, 0.3)',
    },
  })
);

export const SectionContext = React.createContext(
  {} as [string | undefined, Dispatch<SetStateAction<string | undefined>>]
);

interface Props extends RouteComponentProps {
  children: React.ReactNode;
}

function AppLayout({ children, location, history, ...rest }: Props) {
  const settings = useSettings();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const settingsSection = Sections().find((section) => section.name === SectionShortName.Settings) || { path: '' };
  const settingsPath = settingsSection.path;

  if (!settings.authToken) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    if (open) setOpen(false);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar classes={{ regular: classes.regularToolBar, gutters: classes.toolBarGutters }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={location.pathname === settingsPath ? handleBack : handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
              size="large"
              data-testid={location.pathname === settingsPath ? 'settings-back-button' : 'open-menu-button'}
            >
              {location.pathname === settingsPath ? (
                <ChevronLeftIcon
                  classes={{
                    root: classes.headerIcons,
                  }}
                />
              ) : (
                <MenuIcon
                  classes={{
                    root: classes.headerIcons,
                  }}
                />
              )}
            </IconButton>
            <Box className={classes.barContainer}>
              <Box className={classes.sectionTitle}>
                <HeaderTitle />
              </Box>
              <Box className={classes.btnSettings}>
                <Stack direction="row" alignItems="stretch" gap={rem(32)}>
                  <IconButton
                    disabled={open}
                    component={Link}
                    to={settingsPath}
                    className={classes.settingsIconContainer}
                    data-testid="settings-link"
                  >
                    <SettingsIcon
                      classes={{
                        root: classes.settingHeaderIcon,
                      }}
                    />
                  </IconButton>
                  <PauseContainer />
                </Stack>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          ModalProps={{ onClose: handleDrawerClose }}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose} size="large" data-testid="close-menu-button">
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
            <img src={UeatLogo} alt="UEAT" className={classes.logo} />
          </div>
          <Divider />
          <List>
            <Menu handleClick={handleClick} />
          </List>
        </Drawer>
        <main className={classes.content}>{children}</main>
      </div>
    </div>
  );
}

export default withRouter(AppLayout);
