import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ActionButtons, ActionButtonsProps } from '@ueat/poke';
import { rem } from '../../app/AppUtilities';
import { getComponentVariantStyles } from '../../app/muiTheme';

const useStyles = makeStyles((theme: Theme) => {
  const bigContainedBtnStyles = getComponentVariantStyles('MuiButton', 'bigContained', theme);
  return createStyles({
    actionButtonNext: {
      padding: `${rem(28)} ${rem(56)} ${rem(32)}`,
      display: 'flex',
      justifyContent: 'space-between',

      '& >:only-child': {
        display: 'flex',
        flex: 1,
      },

      '& .MuiButtonBase-root': {
        ...bigContainedBtnStyles,
        '&:hover': {
          background: theme.palette.primary.contrastText,
        },
        '&:active': {
          background: theme.palette.primary.contrastText,
        },
      },

      '& .MuiButton-containedPrimary': {
        marginLeft: 'auto',
        '&:hover': {
          background: `${theme.palette.primary.main} !important`, //keep important since the bigContained class in the theme already has another background color
          boxShadow: theme.generalStyles.buttonHoverBoxShadow,
          filter: theme.generalStyles.buttonHoverFilter,
          '& .MuiTypography-root': {
            color: theme.palette.primary.contrastText,
          },
        },
      },
    },
  });
});

export type DrawerFooterProps = ActionButtonsProps;

export default function DrawerFooter(props: DrawerFooterProps) {
  const styles = useStyles();
  return <ActionButtons {...props} className={styles.actionButtonNext} />;
}
