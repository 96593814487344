export enum PrintConnection {
  USB = 'usb',
  Network = 'network',
  Bluetooth = 'bluetooth',
}

export enum PrintBrand {
  None = 'none',
  Epson = 'epson',
  StarMicronics = 'star',
  HPRT = 'hprt',
}

export interface DropdownValueType {
  value: string;
  label: string;
}

export interface SettingsPageType {
  title: string;
  branch: string;
  restaurant: string;
  languageTitle: string;
  branchTitle: string;
  restaurantTitle: string;
  alertsTitle: string;
  alertsLabel: string;
  soundLabel: string;
  sound: string;
  printTitle: string;
  printStatus: string;
  printDisabledTitle: string;
  printDisabledDescription: string;
  automaticPrint: string;
  printBrand: string;
  printBrandList: DropdownValueType[];
  printModel: string;
  epsonPrintModelList: DropdownValueType[];
  starPrintModelList: DropdownValueType[];
  hprtPrintModelList: DropdownValueType[];
  printConnection: string;
  printConnectionList: DropdownValueType[];
  printIpAddress: string;
  logOut: string;
  serialNumber: string;
  loading: string;
  error: string;
}

export const enCa: SettingsPageType = {
  title: 'settings',
  branch: 'Branch',
  restaurant: 'Restaurant',
  languageTitle: 'language choice',
  branchTitle: 'branch selection',
  restaurantTitle: 'restaurant selection',
  alertsTitle: 'alerts profile',
  alertsLabel: 'visual alerts',
  soundLabel: 'sound alerts',
  sound: 'sound',
  printTitle: 'printing',
  printStatus: 'off / on',
  printDisabledTitle: 'Printing disabled',
  printDisabledDescription: 'Your orders are printing from your POS system.',
  automaticPrint: 'Automatic print',
  printBrand: 'Brand',
  printBrandList: [
    { value: PrintBrand.None, label: 'None' },
    { value: PrintBrand.Epson, label: 'Epson' },
    { value: PrintBrand.StarMicronics, label: 'Star Micronics' },
    { value: PrintBrand.HPRT, label: 'HPRT' },
  ],
  printModel: 'Model',
  epsonPrintModelList: [
    { value: 'TM-T20', label: 'TM-T20' },
    { value: 'TM-T88', label: 'TM-T88' },
    { value: 'TM-M30III', label: 'TM-M30III' },
  ],
  starPrintModelList: [
    { value: 'mC-Print', label: 'mC-Print' },
    { value: 'SP700', label: 'SP700' },
    { value: 'TSP143', label: 'TSP143' },
  ],
  hprtPrintModelList: [
    { value: 'TP806L', label: 'TP806L' },
    { value: 'TP808', label: 'TP808' },
  ],
  printConnection: 'Connection',
  printConnectionList: [
    { value: PrintConnection.USB, label: 'USB' },
    { value: PrintConnection.Network, label: 'Network' },
    { value: PrintConnection.Bluetooth, label: 'Bluetooth' },
  ],
  printIpAddress: 'IP Address',
  logOut: 'log out',
  serialNumber: 'Serial Number',
  loading: 'Loading',
  error: 'Error',
};

export const frCa: SettingsPageType = {
  title: 'paramètres',
  branch: 'Succursale',
  restaurant: 'Restaurant',
  languageTitle: 'choix de langue',
  restaurantTitle: 'sélection du restaurant',
  branchTitle: 'sélection de la succursale',
  alertsTitle: 'configuration des alertes',
  alertsLabel: 'alertes visuelles',
  soundLabel: 'alertes sonores',
  sound: 'son',
  printTitle: 'impression',
  printStatus: 'désactivée / activée',
  printDisabledTitle: 'Impression désactivée',
  printDisabledDescription: "L'impression des commandes est géré par votre système POS.",
  automaticPrint: 'Impression automatique',
  printBrand: 'Marque',
  printBrandList: [
    { value: PrintBrand.None, label: 'Aucun' },
    { value: PrintBrand.Epson, label: 'Epson' },
    { value: PrintBrand.StarMicronics, label: 'Star Micronics' },
    { value: PrintBrand.HPRT, label: 'HPRT' },
  ],
  printModel: 'Modèle',
  epsonPrintModelList: [
    { value: 'TM-T20', label: 'TM-T20' },
    { value: 'TM-T88', label: 'TM-T88' },
    { value: 'TM-M30III', label: 'TM-M30III' },
  ],
  starPrintModelList: [
    { value: 'mC-Print', label: 'mC-Print' },
    { value: 'SP700', label: 'SP700' },
    { value: 'TSP143', label: 'TSP143' },
  ],
  hprtPrintModelList: [
    { value: 'TP806L', label: 'TP806L' },
    { value: 'TP808', label: 'TP808' },
  ],
  printConnection: 'Connexion',
  printConnectionList: [
    { value: PrintConnection.USB, label: 'USB' },
    { value: PrintConnection.Network, label: 'Réseau' },
    { value: PrintConnection.Bluetooth, label: 'Bluetooth' },
  ],
  printIpAddress: 'Adresse IP',
  logOut: 'se déconnecter',
  serialNumber: 'Numéro de Série',
  loading: 'Chargement',
  error: 'Erreur',
};
