import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { OptionAndExtra } from './OrderDetailTypes';
import FormattedPrice from '../formatted/FormattedPrice';
import { rem } from '../app/AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      paddingLeft: rem(30),
      flexBasis: '100%',
    },
    name: {
      display: 'block',
      flexGrow: 1,
      flexBasis: '60%',
      marginBottom: rem(theme.spacing(1)),
    },
    price: {
      display: 'flex',
      flexBasis: '40%',
      marginBottom: rem(theme.spacing(1)),
      justifyContent: 'flex-end',
    },
    quantity: {
      color: theme.palette.primary.main,
    },
    itemContainer: {
      display: 'flex',
    },
  })
);

interface Props {
  listData: OptionAndExtra[] | null;
  currency: string;
}

export default function ItemSubList({ listData, currency }: Props) {
  const classes = useStyles();

  if (!listData || !listData.length) {
    return null;
  }

  return (
    <ul className={classes.list}>
      {listData.map((item) => (
        <li className={classes.itemContainer} key={item.id}>
          <p className={classes.name}>
            {item.name} {item.quantity > 1 && <span className={classes.quantity}>{`X${item.quantity}`}</span>}
          </p>
          <p className={classes.price}>
            <FormattedPrice value={item.regularUnitPrice * item.quantity} currency={currency} />
          </p>
        </li>
      ))}
    </ul>
  );
}
