import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Theme,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { rem } from '../app/AppUtilities';
import { parseGraphQLErrorMessage, parseGraphQLThirdPartyDeliveryErrorMessage } from '../app/ErrorUtils';
import { useTraductions } from '../app/TraductionsContext';
import { REFUND_ORDER_MUTATION, THIRD_PARTY_CANCEL_ORDER_MUTATION } from '../mutations/mutations';
import { OrderChannelEnum } from '../pauseChannel/types/PauseTypes';
import { isThirdPartyOrderIntegrationCancellation } from '../utils/ThirdPartyOrderUtils';
import { Order, OrderNumberLabel, Status } from './OrderDetailTypes';
import SubTitle from './SubTitle';
import { useMutation } from '@apollo/client/react';
import { enqueueSnackbar } from 'notistack';
import { snackbarCloseAction } from '../app/SnackbarActions';

type Props = {
  setOpen: (value: boolean) => void;
  setOrderStatus: (value: Status) => void;
  disableButton: (value: boolean) => void;
  isOpen: boolean;
  order: Order;
  branchId: number;
  orderChannel: OrderChannelEnum;
  isPaid: boolean;
  isThirdPartyDelivery: boolean;
};

interface Data {
  refundOrder: {
    id: string;
    status: Status;
  };
  cancelThirdPartyOrder: {
    id: string;
    status: Status;
  };
}

interface Variables {
  orderId: number;
  branchId: number;
  managerCode: string;
  skipDeliveryCancellation: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      marginTop: '20px',
      marginBottom: '20px',
    },
    input: {
      color: theme.palette.secondary.main,
      lineHeight: 2,
      fontSize: rem(theme.generalStyles.mediumTextSize),
    },
    inputLabel: {
      fontSize: rem(theme.generalStyles.mediumTextSize),
      fontWeight: 'bold',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: rem(theme.spacing(3)),
      fontSize: rem(theme.generalStyles.biggerTextSize),
      fontWeight: 'bold',
      padding: rem(theme.generalStyles.containerPadding),
      borderLeft: `${rem(10)} solid ${theme.palette.primary.main}`,
    },
    titleText: {
      paddingLeft: rem(theme.generalStyles.containerPadding),
      display: 'inline-block',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    icon: {
      height: rem(theme.generalStyles.mediumIconSize),
      width: rem(theme.generalStyles.mediumIconSize),
    },
    button: {
      fontSize: rem(theme.generalStyles.mediumTextSize),
      fontWeight: 'bold',
      padding: theme.generalStyles.modalButtonPadding,
    },
  })
);

export default function RefundOrCancelModal({
  setOpen,
  isOpen,
  order,
  branchId,
  disableButton,
  setOrderStatus,
  orderChannel,
  isPaid,
  isThirdPartyDelivery,
}: Props) {
  const traductions = useTraductions();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [managerCode, setManagerCode] = useState<string | null>(null);
  const [hasErrorManagerCode, setHasErrorManagerCode] = useState<boolean>(false);
  const [skipDeliveryMessage, setSkipDeliveryMessage] = useState<string | null>(null);
  const [skipDeliveryCancellation, setSkipDeliveryCancellation] = useState(!isThirdPartyDelivery);
  const [showPasswordInput, setShowPasswordInput] = useState(true);

  const isThirdPartyOrder = isThirdPartyOrderIntegrationCancellation(orderChannel);
  const thirdPartyIntegrationLabel = OrderNumberLabel[order.channel];

  const CANCEL_OR_REFUND_MUTATION = isThirdPartyOrder ? THIRD_PARTY_CANCEL_ORDER_MUTATION : REFUND_ORDER_MUTATION;

  const [cancelOrRefundOrder, { loading }] = useMutation<Data, Variables>(CANCEL_OR_REFUND_MUTATION, {
    onCompleted: (data) => {
      const successMessage = isPaid
        ? traductions.orderDetailPage.refundSuccessful
        : isThirdPartyOrder
        ? `${traductions.orderDetailPage.cancelThirdPartyOrderSuccessful} ${thirdPartyIntegrationLabel}`
        : traductions.orderDetailPage.cancelSuccessful;

      enqueueSnackbar({
        message: successMessage,
        variant: 'success',
        action: snackbarCloseAction,
      });

      setOrderStatus(isThirdPartyOrder ? data.cancelThirdPartyOrder.status : data.refundOrder.status);
      disableButton(true);
      handleClose();
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors.find((error) => error.extensions.code === 'MANAGER_CODE_NOT_VALID')) {
        enqueueSnackbar({
          message: traductions.orderDetailPage.managerCodeError,
          variant: 'error',
          action: snackbarCloseAction,
        });
        setHasErrorManagerCode(true);
        return;
      }

      const graphQLError = parseGraphQLThirdPartyDeliveryErrorMessage(graphQLErrors);
      if (graphQLError) {
        setSkipDeliveryMessage(getSkipDeliveryMessage(graphQLError?.extensions.code as string));
        setSkipDeliveryCancellation(true);
        setShowPasswordInput(!isThirdPartyDelivery);
        return;
      }

      const errorMessage = networkError ? parseGraphQLErrorMessage(networkError.message) : graphQLErrors[0].message;

      enqueueSnackbar({
        message: errorMessage || traductions.main.genericError,
        variant: 'error',
        action: snackbarCloseAction,
      });
    },
  });

  const handleRefundOrCancel = async () => {
    setHasErrorManagerCode(false);
    if (managerCode === null || managerCode === '') {
      enqueueSnackbar({
        message: traductions.orderDetailPage.managerCodeError,
        variant: 'error',
        action: snackbarCloseAction,
      });
      setHasErrorManagerCode(true);
    } else {
      cancelOrRefundOrder({
        variables: { orderId: order.id, branchId, managerCode: managerCode, skipDeliveryCancellation },
      });
    }
  };

  const handleClose = () => {
    setSkipDeliveryCancellation(!isThirdPartyDelivery);
    setOpen(false);
    setShowPasswordInput(true);
    setManagerCode(null);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setManagerCode(event.target.value);
  };

  const getSkipDeliveryMessage = (errorCode: string) => {
    switch (errorCode) {
      case 'cancellation_error':
        return traductions.orderDetailPage.skipDeliveryQuestion;
      case 'cancel_validation_error':
        return traductions.orderDetailPage.skipCompletedDeliveryQuestion;
      default:
        return null;
    }
  };

  return (
    <>
      <Dialog open={isOpen} maxWidth="sm" fullWidth={true}>
        <DialogTitle
          classes={{
            root: classes.title,
          }}
        >
          <LockIcon className={classes.icon} />
          <span className={classes.titleText}>
            {(isThirdPartyOrder || !isPaid
              ? traductions.orderDetailPage.cancellationOrder
              : traductions.orderDetailPage.refundOrder) + order.shortNumber}
          </span>
        </DialogTitle>
        {showPasswordInput && (
          <DialogContent>
            <FormControl className={classes.formControl} disabled={loading}>
              <InputLabel className={classes.inputLabel} htmlFor="managerPassword">
                {traductions.orderDetailPage.managerCode}
              </InputLabel>
              <Input
                error={hasErrorManagerCode}
                id="managerPassword"
                type={showPassword ? 'text' : 'password'}
                onChange={handleChange}
                className={classes.input}
                fullWidth={true}
                autoFocus
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  'data-testid': 'order-detail-refund-or-cancel-input',
                }}
              />
            </FormControl>
          </DialogContent>
        )}
        {!showPasswordInput && (
          <DialogContent>
            <SubTitle>{skipDeliveryMessage}</SubTitle>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            classes={{
              root: classes.button,
            }}
            variant="outlined"
            onClick={handleClose}
            color="secondary"
            data-testid="order-detail-refund-or-cancel-back-button"
          >
            {traductions.orderDetailPage.back}
          </Button>
          <Button
            classes={{
              root: classes.button,
            }}
            variant="contained"
            onClick={handleRefundOrCancel}
            color="primary"
            data-testid="order-detail-refund-or-cancel-confirm-button"
            disabled={loading}
          >
            {isThirdPartyOrder || !isPaid ? traductions.orderDetailPage.confirm : traductions.orderDetailPage.refund}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
