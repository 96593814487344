import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Item } from './OrderDetailTypes';
import FormattedPrice from '../formatted/FormattedPrice';
import ItemSubList from './ItemSubList';
import ItemElement, { ElementType } from './ItemElement';
import ItemNote from './ItemNote';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quantity: {
      color: theme.palette.primary.main,
    },
    itemContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexShrink: 0,
      flexBasis: 'auto',
    },
  })
);

interface Props {
  itemData: Item;
  currency: string;
}

export default function BillItem({ itemData, currency }: Props) {
  const classes = useStyles();

  if (itemData) {
    return (
      <li className={classes.itemContainer}>
        <ItemElement type={ElementType.Name}>
          {itemData.name} {itemData.quantity > 1 && <span className={classes.quantity}>{`X${itemData.quantity}`}</span>}
        </ItemElement>
        <ItemElement type={ElementType.Price}>
          <FormattedPrice value={itemData.regularUnitPrice * itemData.quantity} currency={currency} />
        </ItemElement>
        <ItemNote note={itemData.note} />
        <ItemSubList listData={itemData.extras} currency={currency} />
        <ItemSubList listData={itemData.options} currency={currency} />
      </li>
    );
  } else {
    return null;
  }
}
