import moment from 'moment';
import { delay, graphql, HttpResponse } from 'msw';
import { PreviewMock } from '../../../.storybook/PreviewMock';
import { PauseChannelData } from './PauseChannelDrawer';
import { OrderChannelEnum, PausedRestaurants, PauseStateStatus, ResumedRestaurants } from '../types/PauseTypes';
import { PauseStatesQueryData } from '../pauseContainer';

const defaultMockedData: PauseStatesQueryData = {
  pauseStates: [],
};

const defaultData: PauseChannelData = {
  branchId: PreviewMock.currentBranch.id,
  channelList: [
    {
      channel: OrderChannelEnum.UBER_EATS,
      status: PauseStateStatus.Success,
    },
    {
      channel: OrderChannelEnum.SKIP_THE_DISHES,
      pausedUntil: moment().add(35, 'minute').toISOString(),
      status: PauseStateStatus.Pending,
    },
    {
      channel: OrderChannelEnum.DOOR_DASH,
      status: PauseStateStatus.Success,
    },
    {
      channel: OrderChannelEnum.GOOGLE_FOOD_ORDERING,
      pausedUntil: moment().add(30, 'minute').toISOString(),
      status: PauseStateStatus.Success,
    },
    {
      channel: OrderChannelEnum.WEB,
      pausedUntil: moment().add(15, 'second').toISOString(),
      status: PauseStateStatus.Success,
    },
  ],
  open: true,
};

const closeData: PauseChannelData = {
  ...defaultData,
  open: false,
};

const pauseRestaurantMock: PausedRestaurants = {
  pauseRestaurant: [
    {
      channel: OrderChannelEnum.WEB,
      pausedUntil: '2030-10-26T14:45:58.8289299Z',
      status: PauseStateStatus.Success,
    },
    {
      channel: OrderChannelEnum.GOOGLE_FOOD_ORDERING,
      pausedUntil: '2050-10-27T14:45:58.8289299Z',
      status: PauseStateStatus.Success,
    },
    {
      channel: OrderChannelEnum.UBER_EATS,
      pausedUntil: '2022-10-26T14:45:58.8289299Z',
      status: PauseStateStatus.Success,
    },
  ],
};

const resumeRestaurantMock: ResumedRestaurants = {
  resumeRestaurant: [
    {
      channel: OrderChannelEnum.UBER_EATS,
      pausedUntil: '2022-10-26T14:45:58.8289299Z',
      status: PauseStateStatus.Success,
    },
  ],
};

const smallDelay = 500;

const mswMock = {
  handlers: [
    graphql.mutation('resumeRestaurant', async () => {
      await delay(smallDelay);
      return HttpResponse.json({
        data: resumeRestaurantMock,
      });
    }),
    graphql.mutation('pauseRestaurant', async () => {
      await delay(smallDelay);
      return HttpResponse.json({
        data: pauseRestaurantMock,
      });
    }),
    graphql.query('pauseStates', () => {
      return HttpResponse.json({
        data: defaultMockedData,
      });
    }),
  ],
};

export const PauseChannelDrawerMock = {
  defaultData,
  closeData,
  mswMock,
  pauseRestaurantMock,
};
