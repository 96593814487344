export interface PauseBannerType {
  pausedBannerText: string;
  resumeBannerText: string;
  buttonResumeText: string;
  buttonPauseText: string;
  channelsText: string;
  error: string;
  doordash: string;
  googleFoodOrdering: string;
  skipTheDishes: string;
  uberEats: string;
  web: string;
  unknown: string;

  pauseChannelTitle: string;
  pauseChannelActionButtonNext: string;
  pauseChannelActionButtonBack: string;
  pauseChannelActionButtonResume: string;
  pauseChannelListActiveTitle: string;
  pauseChannelListPausedTitle: string;
  pauseChannelActive: string;

  pauseChannelDelayTitle: string;
  pauseChannelDelayActionButtonNext: string;
  pauseChannelDelayApplyToAllPausedChannels: string;
  pauseChannelDelay30min: string;
  pauseChannelDelay60min: string;
  pauseChannelDelay120min: string;
  pauseChannelDelayUntilTomorrow: string;

  resumeChannelDialogTitle: string;
  resumeChannelDialogConfirm: string;
  resumeChannelDialogCancel: string;

  pauseChannelSuccessNotification: string;
  pauseChannelErrorNotification: string;
  pauseErrorNotification: string;
  resumeChannelSuccessNotification: string;
  resumeChannelErrorNotification: string;
  resumeErrorNotification: string;
  pauseOrResumeChannelTimeoutNotification: string;
}

export const enCa: PauseBannerType = {
  pausedBannerText: 'On Pause',
  resumeBannerText: 'Accepting\norders',
  buttonResumeText: 'Resume',
  buttonPauseText: 'Pause',
  channelsText: 'Channels',
  error: 'Could not retrieve pause status.',
  doordash: 'DoorDash',
  googleFoodOrdering: 'Order with Google',
  skipTheDishes: 'SkipTheDishes',
  uberEats: 'UberEats',
  web: 'UEAT',
  unknown: 'Unknown',

  pauseChannelTitle: 'Pause new orders',
  pauseChannelActionButtonNext: 'Next',
  pauseChannelActionButtonBack: 'Back',
  pauseChannelActionButtonResume: 'Resume accepting orders',
  pauseChannelListActiveTitle: 'Active channels',
  pauseChannelListPausedTitle: 'Paused channels',
  pauseChannelActive: 'Active',

  pauseChannelDelayTitle: 'For how long do you want to pause your orders?',
  pauseChannelDelayActionButtonNext: 'Pause new orders',
  pauseChannelDelayApplyToAllPausedChannels: 'Apply this delay to the channels already on pause',
  pauseChannelDelay30min: '30 min.',
  pauseChannelDelay60min: '60 min.',
  pauseChannelDelay120min: '120 min.',
  pauseChannelDelayUntilTomorrow: 'Until tomorrow',

  resumeChannelDialogTitle: 'Do you want to resume accepting orders?',
  resumeChannelDialogConfirm: 'Yes',
  resumeChannelDialogCancel: 'Cancel',

  pauseChannelSuccessNotification: '{{channel}} is on pause.',
  pauseChannelErrorNotification: "{{channel}} couldn't be paused. Please try again later.",
  pauseErrorNotification: "The Pause action couldn't be completed. Please try again later.",
  resumeChannelSuccessNotification: '{{channel}} is reactivated.',
  resumeChannelErrorNotification: "{{channel}} couldn't be reactivated. Please try again later.",
  resumeErrorNotification: "The Resume action couldn't be completed. Please try again later.",
  pauseOrResumeChannelTimeoutNotification: 'We are waiting for a response from {{channel}}.',
};

export const frCa: PauseBannerType = {
  pausedBannerText: 'Sur pause',
  resumeBannerText: 'Commandes\nacceptées',
  buttonResumeText: 'Reprendre',
  buttonPauseText: 'Pause',
  channelsText: 'Canaux',
  error: "erreur: incapable d'obtenir l'état de pause.",
  doordash: 'DoorDash',
  googleFoodOrdering: 'Order with Google',
  skipTheDishes: 'SkipTheDishes',
  uberEats: 'UberEats',
  web: 'UEAT',
  unknown: 'Inconnu',

  pauseChannelTitle: 'Interrompre les nouvelles commandes',
  pauseChannelActionButtonNext: 'Suivant',
  pauseChannelActionButtonBack: 'Retour',
  pauseChannelActionButtonResume: 'Reprendre les commandes',
  pauseChannelListActiveTitle: 'Canaux de vente actifs',
  pauseChannelListPausedTitle: 'Canaux de vente en pause',
  pauseChannelActive: 'Actif',

  pauseChannelDelayTitle: 'Pendant combien de temps désirez-vous mettre vos commandes sur pause?',
  pauseChannelDelayActionButtonNext: 'Interrompre les nouvelles commandes',
  pauseChannelDelayApplyToAllPausedChannels: 'Appliquer ce délai à mes canaux déjà en pause',
  pauseChannelDelay30min: '30 min',
  pauseChannelDelay60min: '60 min',
  pauseChannelDelay120min: '120 min',
  pauseChannelDelayUntilTomorrow: "Jusqu'à demain",

  resumeChannelDialogTitle: 'Souhaitez-vous réactiver la réception de commandes?',
  resumeChannelDialogConfirm: 'Oui',
  resumeChannelDialogCancel: 'Annuler',

  pauseChannelSuccessNotification: '{{channel}} est en pause.',
  pauseChannelErrorNotification: "{{channel}} n'a pas pu être mis en pause, veuillez réessayer un peu plus tard.",
  pauseErrorNotification: "La mise en pause n'a pas pu être complétée, veuillez réessayer un peu plus tard.",
  resumeChannelSuccessNotification: '{{channel}} est réactivé.',
  resumeChannelErrorNotification: "{{channel}} n'a pas pu être réactivé, veuillez réessayer un peu plus tard.",
  resumeErrorNotification: "La réactivation n'a pas pu être complétée, veuillez réessayer un peu plus tard.",
  pauseOrResumeChannelTimeoutNotification: 'Nous attendons une réponse de {{channel}}.',
};
